"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRequestObject = void 0;
var _lodash = require("lodash");
var _getUrl = require("./api/getUrl");
var _getParamName = require("./getParamName");
/**
 * Form the request object to be sent to the fetch method
 *
 * @param {Object} requestConfig
 *
 * @return {Request}
 */
var getRequestObject = exports.getRequestObject = function getRequestObject(requestConfig) {
  // Form the body (if any)
  var body;
  if (requestConfig.body) {
    if (requestConfig.upload) {
      body = new FormData();
      (0, _lodash.each)(requestConfig.body, function (value, key) {
        body.append(key, value);
      });
    } else {
      body = JSON.stringify(requestConfig.body);
    }
  }

  // Form the method
  var method = requestConfig.method || 'GET';

  // Form the url
  var url = (0, _getUrl.getUrl)(requestConfig.url, requestConfig.api || 'public');
  if ((0, _lodash.isArray)(requestConfig.segments)) {
    // If there is any suffix to the url
    url += '/' + requestConfig.segments.join('/');
  }
  if ((0, _lodash.isPlainObject)(requestConfig.params)) {
    var urlParams = new URLSearchParams();
    var _loop = function _loop(param) {
      var paramValue = requestConfig.params[param];
      if ((0, _lodash.isArray)(paramValue)) {
        paramValue.forEach(function (paramItem) {
          urlParams.append((0, _getParamName.getParamName)(param) + '[]', paramItem);
        });
      } else {
        urlParams.set((0, _getParamName.getParamName)(param), requestConfig.params[param]);
      }
    };
    for (var param in requestConfig.params) {
      _loop(param);
    }
    url += '?' + urlParams.toString();
  }
  return {
    url: url,
    method: method,
    body: body
  };
};