"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.arrayToObject = void 0;
var _lodash = require("lodash");
var arrayToObject = exports.arrayToObject = function arrayToObject() {
  var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return (0, _lodash.reduce)(arr, function (result, value, key) {
    if (value) {
      result[key] = value;
    }
    return result;
  }, {});
};