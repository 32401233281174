"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateCountry = void 0;
var _validateText = require("./validateText");
var validateCountry = exports.validateCountry = function validateCountry(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return (0, _validateText.validateText)(field, {
    t: t,
    z: z
  }).regex(/^[a-z0-9]+$/i, {
    message: t('invalid-country-code-alphanum')
  }).length(2, {
    message: t('invalid-country-code-2chars')
  });
};