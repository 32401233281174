"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIdColumn = void 0;
var getIdColumn = exports.getIdColumn = function getIdColumn(columns) {
  var columnList = Object.values(columns);
  var idColumn = columnList.find(function (column) {
    return column.name === 'id' || column.id;
  });
  if (!idColumn) {
    throw new Error('Could not find an ID column');
  }
  return idColumn.name;
};