"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStandardFieldDefaultValue = void 0;
var _lodash = require("lodash");
var getStandardFieldDefaultValue = exports.getStandardFieldDefaultValue = function getStandardFieldDefaultValue(field) {
  if (!(0, _lodash.isUndefined)(field.defaultValue)) {
    return field.defaultValue;
  }
  if (field.type === 'boolean') {
    return false;
  }
  return '';
};