import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ResourceViewer } from '../ResourceViewer';
import { FeedbackModal } from '../FeedbackModal';
import { currentResourceState } from '../../recoilState';
import { isResourceEmbeddable } from '../Resource/isResourceEmbeddable';

export const CurrentResource = () => {
    // Get and set the current item
    const item = useRecoilValue(currentResourceState);
    const setItem = useSetRecoilState(currentResourceState);

    const [feedbackOpen, setFeedbackOpen] = useState(true);
    const [viewerOpen, setViewerOpen] = useState(false);

    // If there is an item set
    useEffect(() => {
        // Only if we can embed it
        const embeddable = item ? isResourceEmbeddable(item) : true;

        setViewerOpen(embeddable);
        setFeedbackOpen(!embeddable);
    }, [item]);

    if (!item) {
        return null;
    }

    return (
        <>
            <ResourceViewer
                item={item}
                open={viewerOpen}
                onOpenChange={(visible) => {
                    if (!visible) {
                        setViewerOpen(false);
                        setFeedbackOpen(true);
                    }
                }}
            />

            <FeedbackModal
                open={feedbackOpen}
                onOpenChange={(visible) => {
                    if (!visible) {
                        setItem(null);
                    }
                }}
            />
        </>
    );
};
