import { HeartOff, Microscope, ShoppingCart, Vegan } from 'lucide-react';
import { cn } from 'wtf-components';

export const PublicFeedbackIcon = ({ name = '', className }) => {
    switch (name) {
        case 'BsEmojiFrown':
            return (
                <HeartOff className={cn('w-6 h-6 md:w-8 md:h-8', className)} />
            );
        case 'BsEmojiExpressionless':
            return (
                <Microscope
                    className={cn('w-6 h-6 md:w-8 md:h-8', className)}
                />
            );
        case 'BsEmojiSmile':
            return (
                <ShoppingCart
                    className={cn('w-6 h-6 md:w-8 md:h-8', className)}
                />
            );
        case 'BsEmojiHeartEyes':
            return <Vegan className={cn('w-6 h-6 md:w-8 md:h-8', className)} />;
        default:
            return null;
    }
};
