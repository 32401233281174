"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateText = void 0;
var validateText = exports.validateText = function validateText(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  var rules = z.string();
  if (!field.optional) {
    rules = rules.min(1, {
      message: t('required-field')
    });
  } else {
    rules = rules.optional();
  }
  return rules;
};