"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateBoolean = void 0;
var validateBoolean = exports.validateBoolean = function validateBoolean(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  var rules = z["boolean"]();
  if (!field.optional) {
    rules = rules.refine(function (val) {
      return val === true;
    }, {
      message: t('required-field')
    });
  } else {
    rules = rules.optional();
  }
  return rules;
};