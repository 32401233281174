"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormFieldSwitch", {
  enumerable: true,
  get: function get() {
    return _FormFieldSwitch.FormFieldSwitch;
  }
});
Object.defineProperty(exports, "Switch", {
  enumerable: true,
  get: function get() {
    return _switch.Switch;
  }
});
var _switch = require("../../_shadcn/components/switch");
var _FormFieldSwitch = require("./FormFieldSwitch");