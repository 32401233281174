"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useConfirm = void 0;
var _recoilState = require("../../recoilState");
var _recoil = require("recoil");
var useConfirm = exports.useConfirm = function useConfirm() {
  var setConfirmObj = (0, _recoil.useSetRecoilState)(_recoilState.confirmState);
  return {
    confirm: function confirm(_ref) {
      var title = _ref.title,
        message = _ref.message,
        callback = _ref.callback;
      setConfirmObj({
        visible: true,
        title: title,
        message: message,
        callback: callback
      });
    }
  };
};