"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateUpload = void 0;
var validateUpload = exports.validateUpload = function validateUpload(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return z.string();
};