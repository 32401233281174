"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateTextarea = void 0;
var _validateText = require("./validateText");
var validateTextarea = exports.validateTextarea = function validateTextarea(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return (0, _validateText.validateText)(field, {
    t: t,
    z: z
  });
};