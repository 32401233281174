"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePermissions = void 0;
var _react = require("react");
var _index = require("../index");
var usePermissions = exports.usePermissions = function usePermissions() {
  var _useContext = (0, _react.useContext)(_index.DataTableContext),
    permissions = _useContext.permissions;
  return {
    canView: permissions.indexOf('view') >= 0,
    canEdit: permissions.indexOf('edit') >= 0,
    canRemove: permissions.indexOf('remove') >= 0
  };
};