"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormFieldTextarea", {
  enumerable: true,
  get: function get() {
    return _FormFieldTextarea.FormFieldTextarea;
  }
});
Object.defineProperty(exports, "Textarea", {
  enumerable: true,
  get: function get() {
    return _textarea.Textarea;
  }
});
var _textarea = require("../../_shadcn/components/textarea");
var _FormFieldTextarea = require("./FormFieldTextarea");