// import { MediaPlayer, MediaProvider } from '@vidstack/react';
import Plyr from "plyr-react"
import "plyr-react/plyr.css"

import { Modal, ModalContainer } from 'wtf-components';

import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';

import './style.scss';

export const ResourceViewer = ({ item, open, onOpenChange }) => {
    const plyrProps = {
  source: {
    type: 'video',
    sources: [
        {
        src: item.link,
        provider: item.linkType,
        },
    ],
  },
  options: {
    autoplay: true,
  },
};

    return (
        <div className="resource-viewer">
            <ModalContainer open={open} onOpenChange={onOpenChange}>
                <Modal
                    aspectRatio="video"
                    className="w-full sm:w-2/3 overflow-hidden"
                    setWidth={false}
                    title={item.title}
                    description={item.description}
                >
                    {item.linkType === 'embedded' && (
                        <div
                            className="embedded"
                            dangerouslySetInnerHTML={{ __html: item.link }}
                        />
                    )}
                    {(item.linkType === 'youtube' || item.linkType === 'vimeo') && (
                        <Plyr {...plyrProps} />
                    )}
                </Modal>
            </ModalContainer>
        </div>
    );
};
