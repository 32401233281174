"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setupApi = void 0;
var _config2 = require("./config");
var setupApi = exports.setupApi = function setupApi(_ref) {
  var _ref$base = _ref.base,
    base = _ref$base === void 0 ? '' : _ref$base,
    _ref$endpoints = _ref.endpoints,
    endpoints = _ref$endpoints === void 0 ? {} : _ref$endpoints,
    _ref$name = _ref.name,
    name = _ref$name === void 0 ? 'public' : _ref$name;
  _config2._config[name] = {
    base: base,
    endpoints: endpoints
  };
};