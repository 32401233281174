"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateDatepicker = void 0;
var _validateText = require("./validateText");
var validateDatepicker = exports.validateDatepicker = function validateDatepicker(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return (0, _validateText.validateText)(field, {
    t: t,
    z: z
  });
  // .date({
  //     required_error: t('It is required'),
  //     invalid_type_error: t('That\'s not a date!'),
  // });
};