"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Popover", {
  enumerable: true,
  get: function get() {
    return _popover.Popover;
  }
});
Object.defineProperty(exports, "PopoverContent", {
  enumerable: true,
  get: function get() {
    return _popover.PopoverContent;
  }
});
Object.defineProperty(exports, "PopoverTrigger", {
  enumerable: true,
  get: function get() {
    return _popover.PopoverTrigger;
  }
});
var _popover = require("../../_shadcn/components/popover");