"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Filters = void 0;
function _objectDestructuringEmpty(t) { if (null == t) throw new TypeError("Cannot destructure " + t); }
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
/**
 * Component used to DEFINE the filters for a datatable
 */
var Filters = exports.Filters = function Filters(_ref) {
  var props = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
  return null;
};
Filters.displayName = 'Filters';