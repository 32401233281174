import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import store from 'store2';
import { Button, Modal, ModalContainer, useTranslations } from 'wtf-components';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import { FeedbackForm } from '../FeedbackForm';
import { ResourceList } from '../ResourceList';
import {
    currentCodeState,
    currentResourceState,
    resourcesState,
} from '../../recoilState';
import { getFeedbackKey } from '../FeedbackForm/getFeedbackKey';

export const FeedbackModal = ({ open, onOpenChange }) => {
    const { t, currentLanguage } = useTranslations();
    const resources = useRecoilValue(resourcesState);
    const currentResource = useRecoilValue(currentResourceState);
    const [challengesVisible, setChallengesVisible] = useState(false);

    const footer = (
        <div className="p-6 pt-2">
            <Button onClick={() => setChallengesVisible(true)}>
                <div className="flex flex-row items-center">
                    <div className="me-2">{t('review-try-vegan')}</div>
                    {currentLanguage.direction === 'ltr' ? (
                        <ArrowRight />
                    ) : (
                        <ArrowLeft />
                    )}
                </div>
            </Button>
        </div>
    );

    const footerChallenges = (
        <div className="p-6 pt-2">
            <Button onClick={() => setChallengesVisible(false)}>
                <div className="flex flex-row items-center">
                    {currentLanguage.direction === 'ltr' ? (
                        <ArrowLeft />
                    ) : (
                        <ArrowRight />
                    )}
                    <div className="ms-2">{t('button-back')}</div>
                </div>
            </Button>
        </div>
    );

    // Has the user already given feedback
    const currentCode = useRecoilValue(currentCodeState);
    const alreadyGaveFeedback = store.get(
        getFeedbackKey(currentResource.id, currentCode)
    );

    // Filter the other resources to not include the current resource
    const otherResources = resources.byType[currentResource.type].filter(
        (item) => item.id !== currentResource.id
    );

    // Paginate the other resources
    const perPage = 12;
    const [page, setPage] = useState(1);
    const limitStart = 0;
    const limitEnd = page * perPage;
    const paginatedOtherResources = otherResources.slice(limitStart, limitEnd);

    // Hide the challenges when the resource changes and go back to page 1
    useEffect(() => {
        setChallengesVisible(false);
        setPage(1);
    }, [currentResource]);

    return (
        <ModalContainer open={open} onOpenChange={onOpenChange}>
            <Modal
                title={
                    <div className="px-6 pt-6 space-y-0">
                        <img
                            className="h-6"
                            src="/images/3movies-logo.svg"
                            alt={t('3movies')}
                        />
                    </div>
                }
                className={`w-10/12 sm:w-2/3 lg:w-[900px] h-2/3 p-0`}
                footer={challengesVisible ? footerChallenges : footer}
                setWidth={false}
                overflow
            >
                {challengesVisible ? (
                    <div className="p-6 pt-2">
                        {' '}
                        <ResourceList
                            title={t('review-ready-vegan')}
                            items={resources.byType.challenge}
                        />
                    </div>
                ) : (
                    <>
                        {!alreadyGaveFeedback && (
                            <FeedbackForm className="p-6 pt-2" />
                        )}

                        {otherResources && otherResources.length > 0 && (
                            <div className="bg-gray-100 p-6 border-t border-gray-300 dark:bg-gray-900 dark:border-gray-500">
                                <ResourceList
                                    title={t('review-might-also-like')}
                                    items={paginatedOtherResources}
                                />

                                {otherResources.length > limitEnd && (
                                    <div className="flex flex-col items-center mt-4">
                                        <div className="w-48">
                                            <Button
                                                variant="outline"
                                                onClick={() =>
                                                    setPage(page + 1)
                                                }
                                            >
                                                {t('load-more-resources')}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}
            </Modal>
        </ModalContainer>
    );
};
