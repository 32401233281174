"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDefaultColumnProps = void 0;
var getDefaultColumnProps = exports.getDefaultColumnProps = function getDefaultColumnProps() {
  return {
    name: undefined,
    type: 'text',
    serverName: undefined,
    sortable: true,
    label: undefined,
    className: undefined,
    defaultOrderBy: 'asc',
    render: undefined
  };
};