"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateCode = void 0;
var _validateText = require("./validateText");
var validateCode = exports.validateCode = function validateCode(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return (0, _validateText.validateText)(field, {
    t: t,
    z: z
  }).regex(/^[a-z0-9]+$/i, {
    message: t('invalid-code-alphanum')
  }).length(5, {
    message: t('invalid-code-5chars')
  });
};