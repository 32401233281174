import { useRecoilValue } from 'recoil';
import { Resource } from '../../components/Resource';
import { getHighlightedMovies } from './getHighlightedMovies';
import { resourcesState, highlightedState } from '../../recoilState';

export const Movies = () => {
    const resources = useRecoilValue(resourcesState);
    const highlighted = useRecoilValue(highlightedState);
    const movies = getHighlightedMovies(resources, highlighted) || [];

    // Get the first item
    const heroItem = movies.find(Boolean);

    return (
        <div className="movies">
            <Resource item={heroItem} layout="vertical" />

            <ul className="grid sm:grid-cols-2 sm:gap-6 md:gap-10 lg:gap-14 items-stretch">
                {movies.map((item, index) => {
                    // Ignore the first item (since it's the hero)
                    if (index <= 0) {
                        return null;
                    }

                    return (
                        <li
                            className="mt-6 sm:mt-6 md:mt-10 lg:mt-14 break-before-column flex items-stretch"
                            key={item.id}
                        >
                            <Resource item={item} layout="vertical" />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
