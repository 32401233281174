"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGeneratedComponentMatrix = void 0;
var _StandardCheckbox = require("../definitions/StandardCheckbox");
var _StandardCodeInput = require("../definitions/StandardCodeInput");
var _StandardCombobox = require("../definitions/StandardCombobox");
var _StandardDatePicker = require("../definitions/StandardDatePicker");
var _StandardTextInput = require("../definitions/StandardTextInput");
var _StandardNumberInput = require("../definitions/StandardNumberInput");
var _StandardPasswordInput = require("../definitions/StandardPasswordInput");
var _StandardSelect = require("../definitions/StandardSelect");
var _StandardSwitch = require("../definitions/StandardSwitch");
var _StandardTextarea = require("../definitions/StandardTextarea");
var _StandardUploadInput = require("../definitions/StandardUploadInput");
var _Checkbox = require("../../Checkbox");
var _CodeInput = require("../../CodeInput");
var _Combobox = require("../../Combobox");
var _DatePicker = require("../../DatePicker");
var _TextInput = require("../../TextInput");
var _NumberInput = require("../../NumberInput");
var _PasswordInput = require("../../PasswordInput");
var _Select = require("../../Select");
var _Switch = require("../../Switch");
var _Textarea = require("../../Textarea");
var _UploadInput = require("../../UploadInput");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } // Definitions
// Replacements
var getGeneratedComponentMatrix = exports.getGeneratedComponentMatrix = function getGeneratedComponentMatrix() {
  var _ref;
  return _ref = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, _StandardCheckbox.StandardCheckbox.displayName, {
    Component: _Checkbox.FormFieldCheckbox,
    type: 'boolean'
  }), _StandardCodeInput.StandardCodeInput.displayName, {
    Component: _CodeInput.FormFieldCodeInput,
    type: 'code'
  }), _StandardCombobox.StandardCombobox.displayName, {
    Component: _Combobox.FormFieldCombobox,
    type: 'combobox'
  }), _StandardDatePicker.StandardDatePicker.displayName, {
    Component: _DatePicker.FormFieldDatePicker,
    type: 'datepicker'
  }), _StandardTextInput.StandardTextInput.displayName, {
    Component: _TextInput.FormFieldTextInput,
    type: 'text'
  }), _StandardNumberInput.StandardNumberInput.displayName, {
    Component: _NumberInput.FormFieldNumberInput,
    type: 'number'
  }), _StandardPasswordInput.StandardPasswordInput.displayName, {
    Component: _PasswordInput.FormFieldPasswordInput,
    type: 'password'
  }), _StandardSelect.StandardSelect.displayName, {
    Component: _Select.FormFieldSelect,
    type: 'select'
  }), _StandardSwitch.StandardSwitch.displayName, {
    Component: _Switch.FormFieldSwitch,
    type: 'boolean'
  }), _StandardTextarea.StandardTextarea.displayName, {
    Component: _Textarea.FormFieldTextarea,
    type: 'textarea'
  }), _defineProperty(_ref, _StandardUploadInput.StandardUploadInput.displayName, {
    Component: _UploadInput.FormFieldUploadInput,
    type: 'upload'
  });
};