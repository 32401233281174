"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInterpolatedString = void 0;
var _lodash = require("lodash");
/**
 * Interpolates variables wrapped with `{}` in `str` with variables in `obj`
 * It will replace what it can, and leave the rest untouched
 *
 * @param {String} str
 * @param {Array|Object} obj
 *
 * @return {String}
 */
var getInterpolatedString = exports.getInterpolatedString = function getInterpolatedString() {
  var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var obj = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return str.replace(/{([^{}]*)}/g, function (match, keyword) {
    var replace = obj[keyword];
    return (0, _lodash.isString)(replace) || (0, _lodash.isNumber)(replace) ? replace : match;
  });
};