"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseSuperRefinements = void 0;
var _lodash = require("lodash");
var parseSuperRefinements = exports.parseSuperRefinements = function parseSuperRefinements() {
  var standardSchema = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    values = _ref.values,
    ctx = _ref.ctx,
    t = _ref.t;
  standardSchema.forEach(function (field) {
    // Confirmation property
    if (field.confirmation) {
      if (values[field.name] !== values[field.confirmation]) {
        ctx.addIssue({
          code: 'custom',
          message: t("".concat(field.confirmation, "-does-not-match")),
          path: [field.name]
        });
      }
    }

    // Not allowed property
    if (field.notAllowed) {
      var notAllowed = (0, _lodash.isArray)(field.notAllowed) ? field.notAllowed : [field.notAllowed];
      if (notAllowed.includes(values[field.name])) {
        ctx.addIssue({
          code: 'custom',
          message: field.notAllowedMessage || t("".concat(field.name, "-not-allowed-value")),
          path: [field.name]
        });
      }
    }
  });
};