"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStandardSchema = void 0;
var _zod = require("zod");
var _zod2 = require("@hookform/resolvers/zod");
var _reactHookForm = require("react-hook-form");
var _getZodSchema2 = require("./getZodSchema");
var _useTranslations2 = require("../useTranslations");
var _parseSuperRefinements = require("./parseSuperRefinements");
var _getValuesFromSchema = require("./getValuesFromSchema");
var useStandardSchema = exports.useStandardSchema = function useStandardSchema() {
  var standardSchema = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var _useTranslations = (0, _useTranslations2.useTranslations)(),
    t = _useTranslations.t;

  // Get the zod schema
  var _getZodSchema = (0, _getZodSchema2.getZodSchema)(standardSchema, {
      t: t,
      z: _zod.z
    }),
    zodSchema = _getZodSchema.zodSchema,
    defaultValues = _getZodSchema.defaultValues;

  // Get the values
  var values = (0, _getValuesFromSchema.getValuesFromSchema)(standardSchema);

  // Make the zod object
  var zodObject = _zod.z.object(zodSchema).superRefine(function (values, ctx) {
    (0, _parseSuperRefinements.parseSuperRefinements)(standardSchema, {
      values: values,
      ctx: ctx,
      t: t
    });
  });
  return (0, _reactHookForm.useForm)({
    resolver: (0, _zod2.zodResolver)(zodObject),
    defaultValues: defaultValues,
    values: values
  });
};