"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.objectToArray = void 0;
var _lodash = require("lodash");
var objectToArray = exports.objectToArray = function objectToArray() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var count = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  // Create an empty array with a certain length
  var _final = [];
  _final[count - 1] = undefined;

  // Return the array
  return (0, _lodash.reduce)(obj, function (result, value, key) {
    result[key] = value;
    return result;
  }, _final);
};