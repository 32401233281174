"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateEmail = void 0;
var _validateText = require("./validateText");
var validateEmail = exports.validateEmail = function validateEmail(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return (0, _validateText.validateText)(field, {
    t: t,
    z: z
  }).email({
    message: t('signup-invalid-email')
  });
};