"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAppliedFiltersCount = void 0;
var getAppliedFiltersCount = exports.getAppliedFiltersCount = function getAppliedFiltersCount(appliedFilters) {
  // If no filters are set up
  if (!appliedFilters) {
    return 0;
  }
  return Object.values(appliedFilters).filter(Boolean).length;
};