"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.traverseElements = void 0;
var _lodash = require("lodash");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var _traverseElements = exports.traverseElements = function traverseElements(children) {
  var displayNames = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var callback = arguments.length > 2 ? arguments[2] : undefined;
  var level = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  var handleChild = function handleChild(child) {
    var _child$type, _child$props;
    var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    if (displayNames.includes(child === null || child === void 0 || (_child$type = child.type) === null || _child$type === void 0 ? void 0 : _child$type.displayName)) {
      return callback(child, level, index);
    }
    if (child !== null && child !== void 0 && (_child$props = child.props) !== null && _child$props !== void 0 && _child$props.children) {
      var result = _traverseElements(child.props.children, displayNames, callback, level + 1);

      // The user manipulated the children
      if (!(0, _lodash.isUndefined)(result)) {
        return _objectSpread(_objectSpread({}, child), {}, {
          props: _objectSpread(_objectSpread({}, child.props), {}, {
            children: result
          })
        });
      }
    }
    return child;
  };
  if (!(0, _lodash.isArray)(children)) {
    return handleChild(children);
  }
  return children.map(handleChild);
};