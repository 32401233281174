"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateSelect = void 0;
var _validateText = require("./validateText");
var validateSelect = exports.validateSelect = function validateSelect(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return (0, _validateText.validateText)(field, {
    t: t,
    z: z
  });
  /* .refine(
      (value) => {
          // If there is no option provided and it's optional then ignore
          // this rule
          if (!value && field.optional) {
              return true;
          }
           const found = field.options.find((item) => item.value === value);
          return Boolean(found);
      },
      {
          message: t('not-in-options'),
      }
  ); */
};