"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformServerData = void 0;
var transformServerData = exports.transformServerData = function transformServerData() {
  var serverData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = serverData.data;
  var pagination = {
    current: serverData.meta.currentPage,
    total: serverData.meta.lastPage,
    perPage: serverData.meta.perPage,
    countFrom: serverData.meta.from,
    countTo: serverData.meta.to,
    countTotal: serverData.meta.total
  };
  return {
    data: data,
    pagination: pagination
  };
};