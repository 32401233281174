"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StandardCodeInput = void 0;
var _excluded = ["name", "label", "className", "onChange", "visible"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
/**
 * Component used to DEFINE a code input for the generated form
 */
var StandardCodeInput = exports.StandardCodeInput = function StandardCodeInput(_ref) {
  var name = _ref.name,
    label = _ref.label,
    className = _ref.className,
    onChange = _ref.onChange,
    visible = _ref.visible,
    props = _objectWithoutProperties(_ref, _excluded);
  return null;
};
StandardCodeInput.displayName = 'StandardCodeInput';