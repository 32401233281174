"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateNot = void 0;
var validateNot = exports.validateNot = function validateNot(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  return z.custom();
};