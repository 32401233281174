"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStandardForm = void 0;
var _getStandardSchema = require("../helpers/getStandardSchema");
var _getStandardRequestObject = require("../helpers/getStandardRequestObject");
var _getStandardDefaultValues = require("../helpers/getStandardDefaultValues");
var _useFormSubmit2 = require("./useFormSubmit");
var _useStandardSchema = require("./useStandardSchema");
var useStandardForm = exports.useStandardForm = function useStandardForm(_ref) {
  var _ref$schema = _ref.schema,
    schema = _ref$schema === void 0 ? {} : _ref$schema,
    requestObject = _ref.requestObject,
    onSuccess = _ref.onSuccess,
    onError = _ref.onError,
    _ref$options = _ref.options,
    options = _ref$options === void 0 ? {} : _ref$options;
  var standardSchema = (0, _getStandardSchema.getStandardSchema)(schema, options);
  var _useFormSubmit = (0, _useFormSubmit2.useFormSubmit)({
      requestObject: (0, _getStandardRequestObject.getStandardRequestObject)(requestObject, standardSchema),
      onSuccess: onSuccess,
      onError: onError,
      options: options
    }),
    error = _useFormSubmit.error,
    loading = _useFormSubmit.loading,
    formSubmit = _useFormSubmit.formSubmit;

  // Get the form properties
  var form = (0, _useStandardSchema.useStandardSchema)(standardSchema);
  return {
    error: error,
    loading: loading,
    form: {
      instance: form,
      submit: formSubmit,
      trigger: form.trigger,
      reset: function reset(values) {
        var defaultValues = (0, _getStandardDefaultValues.getStandardDefaultValues)(standardSchema);
        form.reset(values || defaultValues);
      }
    }
  };
};