import { useNavigate } from 'react-router-dom';
import { useLanguageAndCodeFromUrl } from './useLanguageAndCodeFromUrl';
import { getUrl } from '../helpers/getUrl';

export const useChangeLanguage = () => {
    const { code } = useLanguageAndCodeFromUrl();
    const navigate = useNavigate();

    const changeLanguage = (language = '', ...params) => {
        const url = getUrl(language, code);
        navigate(url, ...params);
    };

    return changeLanguage;
};
