"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUrl = void 0;
var _getApiConfig = require("./getApiConfig");
var getUrl = exports.getUrl = function getUrl() {
  var endpoint = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'public';
  var currentConfig = (0, _getApiConfig.getApiConfig)(key);
  if (!currentConfig.endpoints[endpoint]) {
    throw new Error("API endpoint \"".concat(endpoint, "\" not found"));
  }
  return currentConfig.base + currentConfig.endpoints[endpoint];
};