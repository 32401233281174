"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Search = void 0;
/**
 * Component used to PASS the search of the datatable
 */
var Search = exports.Search = function Search(_ref) {
  var children = _ref.children;
  return null;
};
Search.displayName = 'Search';