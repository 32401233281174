"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateNumber = void 0;
var validateNumber = exports.validateNumber = function validateNumber(field, _ref) {
  var t = _ref.t,
    z = _ref.z;
  // return validateText(field, {t,z})
  return z.number();
};