"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getApiConfig = void 0;
var _config2 = require("./config");
var getApiConfig = exports.getApiConfig = function getApiConfig() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'public';
  if (!_config2._config[name]) {
    throw new Error("API \"".concat(name, "\" not setup. Please run `setupApi` to set it up"));
  }
  if (!_config2._config[name].base) {
    throw new Error("Base For API \"".concat(name, "\" not setup."));
  }
  if (!_config2._config[name].endpoints) {
    throw new Error("Endpoints For API \"".concat(name, "\" not setup."));
  }
  return _config2._config[name];
};