"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serverPermissionsToTablePermissions = void 0;
var serverPermissionsToTablePermissions = exports.serverPermissionsToTablePermissions = function serverPermissionsToTablePermissions(permissions) {
  var _final = [];
  if (permissions.viewAny) {
    _final.push('view', 'edit');
  }
  if (permissions.create) {
    _final.push('add');
  }
  return _final;
};