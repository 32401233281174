import {
    Clapperboard,
    Video,
    UtensilsCrossed,
    LaptopMinimal,
    LayoutGrid,
    BookOpenText,
} from 'lucide-react';
import { BigTabs, BigTab, useTranslations } from 'wtf-components';
import { ResourcesTab } from './ResourcesTab';
import { SectionHeading } from '../../components/SectionHeading';

export const Resources = () => {
    const { t } = useTranslations();

    return (
        <div className="resources">
            <SectionHeading className="mb-4 sm:mb-6" text="resources-heading" />

            <BigTabs>
                <BigTab
                    name={t('resources-tab-videos')}
                    icon={<Clapperboard className="w-6 h-6" />}
                >
                    <ResourcesTab category="video" />
                </BigTab>
                <BigTab
                    name={t('resources-tab-documentaries')}
                    icon={<Video className="w-6 h-6" />}
                >
                    <ResourcesTab category="documentary" />
                </BigTab>
                <BigTab
                    name={t('resources-tab-challenges')}
                    icon={<UtensilsCrossed className="w-6 h-6" />}
                >
                    <ResourcesTab category="challenge" />
                </BigTab>
                <BigTab
                    name={t('resources-tab-websites')}
                    icon={<LaptopMinimal className="w-6 h-6" />}
                >
                    <ResourcesTab category="website" />
                </BigTab>
                <BigTab
                    name={t('resources-tab-apps')}
                    icon={<LayoutGrid className="w-6 h-6" />}
                >
                    <ResourcesTab category="app" />
                </BigTab>
                <BigTab
                    name={t('resources-tab-books')}
                    icon={<BookOpenText className="w-6 h-6" />}
                >
                    <ResourcesTab category="book" itemLayout="horizontal" />
                </BigTab>
            </BigTabs>
        </div>
    );
};
