"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderCell = void 0;
var _lodash = require("lodash");
var renderCell = exports.renderCell = function renderCell(field, item) {
  var value = item[field.name];
  if ((0, _lodash.isFunction)(field.render)) {
    return field.render(value, item);
  }
  return value;
};