import { useSetRecoilState } from 'recoil';
import { Resource as CommonResource } from 'wtf-components';
import { currentResourceState } from '../../recoilState';
import { isResourceEmbeddable } from './isResourceEmbeddable';
import { getResourceUrl } from './getResourceUrl';

export const Resource = ({
    item,
    layout = 'vertical',
    highlighted = false,
}) => {
    const setCurrentResource = useSetRecoilState(currentResourceState);

    return (
        <CommonResource
            image={item.image}
            title={item.title}
            description={item.description}
            layout={layout}
            highlighted={highlighted}
            onClick={() => {
                if (!isResourceEmbeddable(item)) {
                    const url = getResourceUrl(item);
                    window.open(url);
                }

                // We set the current item regardless
                setCurrentResource(item);
            }}
        />
    );
};
