import { appConfig } from '../config/app';

export const getUrl = (language, code) => {
    const parsedLanguage = language.toLowerCase();
    const parsedCode = code.toLowerCase();

    let languagePart = encodeURIComponent(parsedLanguage) + '/';
    if (appConfig.defaultLanguage === parsedLanguage || parsedLanguage === '') {
        languagePart = '';
    }

    let codePart = encodeURIComponent(parsedCode);
    if (appConfig.defaultCode.code === code) {
        codePart = '';
    }
    return '/' + languagePart + codePart;
};
