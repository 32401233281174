export const appConfig = {
    /**
     * Default code if the user does not enter any code
     */
    defaultCode: {
        code: '',
        empty: true,
    },

    /**
     * Default language
     */
    defaultLanguage: 'en',
};
